<template>
  <app-page
    :hideBottomBar="user ? false : true"
    container
    :title="$vuetify.lang.t('$vuetify.terms_and_conditions_title')"
  >
    <EnglishTerms v-if="lang == 'en'" />
    <DutchTerms v-else-if="lang == 'nl'" />
    <FrenchTerms v-else />
  </app-page>
</template>

<script>
import EnglishTerms from "./english/terms-conditions.vue";
import DutchTerms from "./dutch/terms-conditions.vue";
import FrenchTerms from "./french/terms-conditions.vue";
import { createNamespacedHelpers } from 'vuex';
const user_module = createNamespacedHelpers('user');
export default {
  name: "terms-conditions",
  components: {
    EnglishTerms,
    DutchTerms,
    FrenchTerms,
  },
  data() {
    return {
      lang: this.$vuetify.lang.current,
    };
  },
  computed: {
    ...user_module.mapState(["user"]),
  },
};
</script>
